import LayoutSection from 'layouts/LayoutSection';
import { FC, useEffect } from 'react';

import Icon from 'atoms/Icons/Icon';
import color from 'atoms/colors/colors';

import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import { Container } from 'components/TemplatesLibrary/styles.js';

type LibraryDetailProps = {
  section: {
    internalName: string;
    id: string;
  };
  setSelectedTemplate: () => null;
};

const TemplatesLibraryDetail: FC<LibraryDetailProps> = ({ section, setSelectedTemplate }) => {
  const sectionToRender = { ...section, backgroundImage: null };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Container>
      <div className="detail-header">
        <div className="inner-container">
          <Text as="p" textStyle="textSm" onClick={() => setSelectedTemplate(null)} link={null} isButton>
            <Icon id="arrow-left" fill={color.common.white} size={16} /> Back to Library
          </Text>
          <Heading as="h2" textStyle="h2">
            {section.internalName}
          </Heading>
        </div>
      </div>
      <LayoutSection key={section.id} {...sectionToRender} responsiveBackgroundImages={null} />
    </Container>
  );
};

export default TemplatesLibraryDetail;

import { FC, useEffect, useState } from 'react';

import searchIcon from 'assets/images/templates-library-search-icon.png';

import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

type LibraryProps = {
  sections: Array<any>;
  setSelectedTemplate: () => null;
};

const TemplatesLibrary: FC<LibraryProps> = ({ sections, setSelectedTemplate }) => {
  const [templatesToRender, setTemplatesToRender] = useState([]);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const sortSections = (templates: any[]) => templates.sort((a, b) => a.internalName.localeCompare(b.internalName));

  useEffect(() => {
    const filterTemplates = () =>
      sections?.filter(section => section.internalName.toLowerCase().includes(searchValue.toLowerCase())) || [];

    setTemplatesToRender(searchValue ? filterTemplates() : sections);
  }, [searchValue]);

  useEffect(() => {
    if (searchOpen) {
      setTimeout(() => {
        document.getElementById('search-input')?.focus();
      }, 100);
    }
  }, [searchOpen]);

  const handleSearchInput = (value: string) => {
    setSearchValue(value);
  };

  return (
    <>
      <div className="top-section">
        <div className="hero">
          <div className="hero-copy">
            <Heading as="h1" textStyle="h2">
              Welcome to the <span>Templates Library</span>
            </Heading>
            <Text as="p" textStyle="textMd" textColor="gray-700">
              Explore our collection of website templates. Click on a card to preview any template.
            </Text>
          </div>
          <div className="hero-feature" onClick={() => setSelectedTemplate(sections[0])} role="presentation">
            <div className="hero-feature-copy">
              <div className="tag">
                <p>new</p>
              </div>
              <Heading as="h2" textStyle="h1">
                {sections[0].internalName}
              </Heading>
            </div>
            <div className="hero-feature-image">
              <img src={sections?.[0]?.responsiveBackgroundImages?.imageDesktop?.file?.url} alt="featured-img" />
            </div>
          </div>
        </div>
        <div className="hero-buttons">
          <div className="template-collection-title hero-button">
            <Text as="p" textColor="gray-900">
              Template Collection
            </Text>
          </div>
          <div className={`hero-button-search hero-button ${searchOpen ? 'open' : 'closed'}`}>
            <img
              src={searchIcon}
              onClick={() => {
                setSearchOpen(!searchOpen);
              }}
              role="presentation"
              alt="search-icon"
            />
            <input
              placeholder="Template Name"
              className={`input ${searchOpen ? 'open' : 'closed'}`}
              value={searchValue}
              onChange={e => handleSearchInput(e.target.value)}
              id="search-input"
            />
          </div>
        </div>
      </div>
      <div className="cards-container">
        {sortSections(templatesToRender).map((section, index) => {
          const { responsiveBackgroundImages, internalName } = section;

          return (
            <div
              className="card"
              onClick={() => {
                setSelectedTemplate(section);
              }}
              key={`card-${index}`}
              role="presentation"
            >
              <div className="card-image">
                {responsiveBackgroundImages?.imageDesktop?.file?.url && (
                  <img src={responsiveBackgroundImages?.imageDesktop?.file?.url} alt="card-feature" />
                )}
              </div>
              <Heading as="h5" textStyle="h5">
                {internalName}
              </Heading>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TemplatesLibrary;

import { PageProps, graphql } from 'gatsby';
import Layout from 'layouts/Layout';
import { FC, useState } from 'react';

import TemplatesLibrary from 'components/TemplatesLibrary/TemplatesLibrary';
import TemplatesLibraryDetail from 'components/TemplatesLibrary/TemplatesLibraryDetail';
import { Container } from 'components/TemplatesLibrary/styles.js';

type DataProps = {
  pageData: {
    sections: Array<any>;
  };
};

const TemplatesLibraryPage: FC<PageProps<DataProps>> = ({
  data: {
    pageData: { sections },
  },
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  return (
    <Layout>
      <Container>
        {!selectedTemplate ? (
          <TemplatesLibrary sections={sections} setSelectedTemplate={setSelectedTemplate} />
        ) : (
          <TemplatesLibraryDetail section={selectedTemplate} setSelectedTemplate={setSelectedTemplate} />
        )}
      </Container>
    </Layout>
  );
};

export default TemplatesLibraryPage;

export const TemplateLibraryPageQuery = graphql`
  query templatePageQuery($slug: String!) {
    pageData: contentfulTemplatePage(slug: { eq: $slug }) {
      ...contentfulTemplatePage
    }
  }
`;

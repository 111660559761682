import styled from 'styled-components';

import color from 'atoms/colors/colors';
import { fontFamily } from 'atoms/typography/typography';

const Container = styled.div`
  background: #f2f8fb;

  .main-container {
    padding: 0;
  }

  .detail-header {
    height: max-content;
    padding: 20px 0 40px 0;
    background: rgb(17, 12, 57);
    color: white;

    .inner-container {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      max-width: 1320px;
      margin: 0 auto;
    }

    p {
      margin-bottom: 40px;
      align-items: center;
      display: flex;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .hero {
    max-width: 1320px;
    width: 100%;
    padding: 5rem 1.25rem 0 1.25rem;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hero-copy {
    width: calc(32% - 30px);
    height: max-content;

    span {
      color: ${color.blue[600]};
    }

    h1 {
      margin-bottom: 10px;
    }

    button {
      height: 44px;
      max-width: 130px;
      width: 100%;
      background: #e24f4f;
      border: none;
      border-radius: 30px;
      font-weight: bold;
      color: white;
      font-size: 16px;
      box-shadow: 0px 2px 0px #b20303;
    }
  }

  .top-section {
    height: 500px;
    position: relative;

    &.selected-template {
      height: 615px;
      margin-bottom: 100px !important;
    }
  }

  .top-section:before {
    content: '';
    background: white;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 60%;
    height: 500px;
    border-radius: 30px 0;
  }

  .top-section.selected-template:before {
    height: 615px;
  }

  .hero-feature {
    width: calc(68% - 30px);
    background: rgb(17, 12, 57);
    padding: 30px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
  }

  .hero-feature-copy {
    width: calc(40% - 20px);
    color: white;

    h2 {
      margin: 0;
      color: white;
    }

    h3 {
      font-size: 20px;
      font-weight: 400;
      color: #eee;
    }

    h4 {
      font-size: 18px;
      margin: 40px 0 0 0;
      color: white;
    }

    ul {
      margin: 5px 0;
      padding: 0 0 0 17px;

      p {
        margin: 0;
        color: white;
      }
    }

    .tag {
      padding: 4px 10px;
      border-radius: 15px;
      background: ${color.green[300]};
      width: max-content;
      margin-bottom: 10px;

      p {
        color: white;
        font-weight: 600;
        margin: 0;
      }
    }
  }

  .hero-feature-image {
    width: calc(60% - 20px);
    border: 1px solid #e1ebf3;
    border-radius: 20px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .hero-buttons {
    max-width: 1280px;
    width: 100%;
    padding: 0 20px;
    position: absolute;
    height: 50px;
    margin: 0 auto;
    left: 50%;
    bottom: -43px;
    transform: translate(-50%, -50%);

    .hero-button {
      width: max-content;
      background: #f2f8fb;
      display: flex;
      position: absolute;
      align-items: center;
      bottom: 0;
      z-index: 9;
      border-radius: 20px;

      img {
        width: 40px;
      }

      p {
        font-weight: 900;
        margin: 0 0 0 15px;
      }
    }

    .template-collection-title {
      left: 100px;
      padding: 12px 20px 0 12px;
    }

    .hero-button-search {
      left: 375px;
      padding: 12px 12px 0 12px;
      transition: width 0.3s ease-in-out;
      width: 65px;
      overflow: hidden;

      img:hover {
        cursor: pointer;
      }

      &.open {
        width: 295px;
      }

      input {
        border-radius: 30px;
        border: none;
        padding: 12px 20px 12px 20px;
        margin-left: 13px;
        box-shadow: inset 2px 2px #c8dfee;
        width: 0;
        transition: width 0.3s ease-in-out;
        color: #555;
        font-size: 16px;
        font-weight: 500;
        height: 40px;
        padding-bottom: 8px;
        font-family: ${fontFamily.secondaryFont};

        &::placeholder {
          color: #ccc;
        }

        &:focus {
          border: none;
          outline: none;
        }

        &.open {
          width: 213px;
        }
      }
    }
  }

  .cards-container {
    max-width: 1280px;
    width: 100%;
    padding: 60px 10px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;

    .card-image {
      height: 200px;
      width: 100%;
      overflow: hidden;
      border-radius: 20px;
      border: 1px solid #e1ebf3;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .card {
      border-radius: 30px;
      background: white;
      padding: 20px;
      width: calc(33% - 18px);
      border: 1px solid #e1ebf3;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
      margin: 0 30px 30px 0;

      h5 {
        color: #17191c;
        margin: 20px 0 7px 0;
      }

      &:hover {
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.1);
        cursor: pointer;
      }
    }
  }

  @media (min-width: 1051px) {
    .cards-container {
      .card {
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 801px) and (max-width: 1050px) {
    .cards-container {
      .card {
        width: calc(33% - 18px);
      }
    }
  }

  @media (max-width: 1050px) {
    .top-section {
      height: 625px;

      &:before {
        height: 615px;
      }
    }

    .hero-buttons {
      bottom: -28px;
    }

    .hero {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 3rem;
    }

    .hero-copy {
      width: 50%;
      margin-bottom: 40px;

      h1 {
        font-size: 30px;
      }

      p {
        font-size: 18px;
      }
    }

    .hero-feature {
      width: 80%;

      .hero-feature-copy {
        h2 {
          font-size: 30px;
        }

        p {
          font-size: 14px;
        }
      }

      .hero-feature-image {
        width: calc(67% - 20px);
        height: 240px;
      }
    }
  }

  @media (min-width: 801px) and (max-width: 1050px) {
    .cards-container {
      .card {
        &:nth-child(3n + 3) {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 0px) and (max-width: 800px) {
    .cards-container {
      .card {
        width: calc(50% - 15px);

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }
    }
  }

  @media (min-width: 505px) and (max-width: 680px) {
    .top-section {
      height: 352px;
    }

    .hero {
      flex-direction: column;
      height: 320px;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .hero-copy button {
      margin-top: 20px;
    }

    .hero-copy {
      width: 75%;
    }

    .toggle--titles {
      border: 1px solid #eee;
      border-radius: 30px;
    }

    .top-section:before {
      width: 80%;
      height: 300px;
    }

    .cards-container {
      padding: 0 10px;
    }

    .hero-buttons {
      left: 37%;
      bottom: 10px;
    }

    .hero-feature {
      display: none;
    }
  }

  @media (min-width: 0px) and (max-width: 504px) {
    .cards-container {
      padding-top: 150px;

      .card-image {
        height: 250px;
      }
    }
    .hero-feature {
      display: none;
    }

    .hero-buttons {
      bottom: -160px;
    }

    .toggle--titles {
      border: 1px solid #eee;
      border-radius: 30px;
    }

    .hero-copy {
      width: 80%;
    }

    .toggle--toggle:before {
      height: 40px;
    }
    .toggle--switch {
      max-width: 250px;

      .toggle--title {
        font-size: 13px;
      }
    }

    .cards-container {
      .card {
        width: 100%;

        margin-right: 0;
      }
    }

    .hero-buttons .template-collection-title {
      left: 11px;
    }

    .hero-buttons .hero-button-search {
      left: 13px;
      bottom: 82px;
    }

    .top-section {
      height: 270px;
    }

    .top-section:before {
      width: 86%;
      height: 300px;
    }
  }
`;

const InnerContainer = styled.div`
  padding-top: 20px;
`;

export { Container, InnerContainer };
